import { useEffect } from "react";

const Statute = () => {
    useEffect(() => {
        window.open("/statute.pdf", "_blank");
    }, []);

    return null;
};

export default Statute;